import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Incoming Quality Control (IQC)",
  "description": "IQC is the first step of the Quality Control chain, aiming to maintain product integrity and enhance supplier network quality. How are these done and what factors are involved in the process?",
  "author": "Happy Fei",
  "categories": ["qc-testing"],
  "date": "2023-01-24T16:00:00.000Z",
  "featured": false,
  "tags": ["qc"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`IQC testing of raw materials is the first checkpoint for the quality control of enterprise products before production. Putting nonconforming products into the manufacturing process will lead to nonconformities in the process or the final products and result in huge losses. IQC impacts not only the quality of the company's final products but also various kinds of direct or indirect costs. In the manufacturing industry, there are usually four major items that have a direct impact on product quality —— design, incoming materials, manufacturing process, and storage and transportation. And generally speaking, design accounts for 25%, incoming materials 50%, manufacturing process 20%, and storage and transportation 1% to 5%. Above all, the incoming inspection plays an overwhelming role in the quality control of the company's products, so IQC shall be raised to a strategic level.`}</p>
    <h3 {...{
      "id": "what-is-incoming-quality-control-iqc"
    }}>{`What is Incoming Quality Control (IQC)?`}</h3>
    <p>{`IQC (Incoming Quality Control) is the quality inspection of incoming materials. IQC refers to the quality confirmation and verification of purchased raw materials, components, or products. IQC is the quality inspection process done by sampling the raw materials sent by suppliers.`}</p>
    <p>{`The output of an IQC process will be the acceptance or return of products.`}</p>
    <h3 {...{
      "id": "the-importance-of-iqc"
    }}>{`The Importance of IQC`}</h3>
    <p>{`IQC is the first checkpoint to control the quality of products before production. Its purpose is to start the QC process from the beginning of the production process. find the quality problems at the very beginning, reduce the quality cost and realize quality control effectively. Putting nonconforming products into the manufacturing process will lead to nonconformities in the manufacturing process or final products and result in huge losses. IQC impacts not only the quality of the company's final products but also various kinds of direct or indirect costs, delivery time, and reputation.`}</p>
    <p>{`In the manufacturing industry, design, incoming materials, manufacturing processes, storage, and transportation usually have a direct impact on product quality. And generally speaking, design accounts for 25%, incoming materials 50%, manufacturing process 25%, and storage and transportation 1% to 5%. To summarize, the incoming inspection plays an overwhelming role in the quality control of the company's products, so IQC shall be raised to a strategic level；`}</p>
    <h3 {...{
      "id": "parts-of-iqc"
    }}>{`Parts of IQC`}</h3>
    <h4 {...{
      "id": "role-of-an-iqc-inspector"
    }}>{`Role of An IQC Inspector`}</h4>
    <p>{`The person in charge of the whole IQC and Quality Control process is the IQC inspector. An IQC inspector has some key roles as stated below:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Incoming inspection`}</strong>{`: the main task of an IQC inspector is to inspect the incoming materials. It can be briefly described as :`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`A part of IQC that aims to confirm the main or all features of the outsourced or purchased materials are up to the relevant standards. This is done to identify whether the materials meet the requirements for applications.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Handling quality problems of incoming materials:`}</strong>{` A part of the IQC procedure is also to track and deal with the quality issues found during the inspection, as well as the major material quality issues reported by production and market, and establish preventive measures within IQC.`}</p>
        <p parentName="li">{`Some large companies with a clear division of labor will set up the post of `}<em parentName="p">{`SQE (Supplier Quality Engineering`}</em>{`) engineer to deal with supplier quality problems. This responsibility is taken by the QC team leader in NexPCB, together with the quality supervisor to improve the quality of our suppliers. In NexPCB, the process includes summarizing incoming inspection results with other inspection reports, generating monthly, and annual reports, etc.`}</p>
      </li>
    </ul>
    <p>{`This being said, the IQC process is not only a window for the company to deal with suppliers but also a gateway to control product quality. The performance of IQC is directly related to the company's product quality cost and the company's impression among suppliers. QC staff are required to be able to do strict sampling, "objective and fair" judgment, respect for suppliers, reduce acceptance by concession, etc.`}</p>
    <h4 {...{
      "id": "process-of-iqc"
    }}>{`Process of IQC`}</h4>
    <p>{`The following is the incoming inspection process set by NexPCB, which starts right away upon the materials' arrival at the warehouse.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "85.32818532818533%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABJ0AAASdAHeZh94AAACw0lEQVQ4y32U3W/cRBTF8/+/IvGABKLiAamVKKhSU8SHGh6aB6qI0oKyH94k9nrtXXvXnzNje2b8gxlvQlKlWLqa8dj3+Jxz7/UJj1zjOE6r1uw3G7I49lHnGVoKPnW5vJNHTu8AbafYXF0RzGes5jOaosCK1n9otHYKY6b1mHPySXa3wNZi+p5DtkOJFtk27H77meTFM5KX37F++oTwmy/odunjgA9Aj/dWa0RdeyZaa6q//iB/85ri9zcczs/Iz35iqMoJ8JbJ/fBgxmCkZJCCKAzZbXfsd1uMUui+Jz8UyK6jGzS9MXd2ndz37n5MHhnvVy9a8jSlrSpvwa1vxhistYi29fs7hv7BUeLH0bcNhzShyjK6usIMA8aBWevBXL4Qwu89oFLKH1TrkMPikiJYUARzv6/iiCbP+PPigqvVCt02qKbG5UghUFIipaQsy4eAdoTt6feEX39O/PQJ62+/4ubLz0hOn9MpRbFN2dzcYJTEGE3x9pz811dkr38k/+WUOo68Qu+hA3T0uySmXV7Srua0wYxm9jcyDr0dnRS+II6Vi/z8jOTVDyQvn5O+eObV/QfYdQxKovuOoe+9R/cLVeYZi9mMIAhYLZZoKdFKsttuaepJ/gPJTpIqDny4uODy/QdvvNjnlEXBcj7zzAYh2MXxNCnHKfH9eWwz56O9rbL7gmPYNY1PdH026oGyrEiTDdt1RHx9zWp2ySFN/TvWjhitp+4whrZpPiqK1jBacH13lNw0De/fvSNaLlhfX9NWpWevqhJnk6uwy3VRVZVn6wH7YaDcpqxXAZsoJEs2XnKxz4miNW1dH+fPgtGP/mXcjLvpuZuUQUpEWSAdg7qepB89afc5N6sVSRSSpwmyKn3fKtUhy4IoWFJkmS+WEe00Kf/zg/ONHQZLsk3sJQ9tM3k3jgxNzTpYkkbhNEX/Av4D1UYYgkRMWWMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/edddffd95f27a389671f81903278f1b0/652f5/iqc-process.webp 259w", "/static/edddffd95f27a389671f81903278f1b0/c29d2/iqc-process.webp 518w", "/static/edddffd95f27a389671f81903278f1b0/7eb86/iqc-process.webp 642w"],
            "sizes": "(max-width: 642px) 100vw, 642px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/edddffd95f27a389671f81903278f1b0/a2ead/iqc-process.png 259w", "/static/edddffd95f27a389671f81903278f1b0/6b9fd/iqc-process.png 518w", "/static/edddffd95f27a389671f81903278f1b0/1bba8/iqc-process.png 642w"],
            "sizes": "(max-width: 642px) 100vw, 642px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/edddffd95f27a389671f81903278f1b0/1bba8/iqc-process.png",
            "alt": "iqc process",
            "title": "iqc process",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "iqc-risks"
    }}>{`IQC Risks`}</h3>
    <p>{`Generally, IQC inspection mainly focuses on sampling inspection. Despite this, the amount of products to be inspected is still sometimes too much to be covered by the IQC team alone. A lot of the IQC process also relies on manual inspection and judgment, which tends to be more subjective. Therefore, IQC cannot eliminate all risks.`}</p>
    <p>{`How to reduce the risk of incoming inspection:`}</p>
    <ol>
      <li parentName="ol">{`First, `}<strong parentName="li">{`select high-quality suppliers`}</strong>{` through ISO review and qualification evaluation to reduce the risk of nonconforming product inflows. The source of the incoming products is the supplier. When selecting your product suppliers, ensuring their capability of providing a stable supply of products should be put into higher priority before the price of the individual products.`}</li>
      <li parentName="ol">{`Then, the chosen suppliers should be able to provide quality assurance to transfer part of the quality pressure to them. This means we `}<strong parentName="li">{`align our own quality standards with our suppliers`}</strong>{`. Suppliers will need to inspect the products to be shipped to ensure efficient IQC can be done on our part.`}</li>
      <li parentName="ol">{`Finally, `}<strong parentName="li">{`scientific and reasonable sampling`}</strong>{` shall be adopted to allocate limited inspection resources to high-risk products and categories, and reduce the sampling ratio of low-risk and risk-free products or exempt them from inspection.`}</li>
    </ol>
    <h3 {...{
      "id": "nexpcbs-iqc"
    }}>{`NexPCB's IQC`}</h3>
    <h4 {...{
      "id": "routine-iqc-procedures"
    }}>{`Routine IQC Procedures:`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Appearance inspection`}</strong>{`: refer to general plastic inspection standards, hardware inspection standards, PCB inspection standards, and project-specific inspection standards.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dimensional inspection`}</strong>{`: Inspects based on the drawing and specifications provided by ME or the suppliers. The common tools used include calipers, micrometers, plug gauges, tooth gauges, and height gauges.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hardness test`}</strong>{`: hardness testing of materials with hardness requirements. The common tool used is the hardness agent`}</li>
      <li parentName="ul"><strong parentName="li">{`Assembly inspection`}</strong>{`: indirect inspection by simulating physical assembly, using boxes/cartons and other packaging products.`}</li>
    </ul>
    <h4 {...{
      "id": "special-iqc-procedures"
    }}>{`Special IQC Procedures`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Salt spray test`}</strong>{`: regular 48-hour salt spray testing on metal parts;`}</li>
      <li parentName="ul"><strong parentName="li">{`Plastic stress test`}</strong>{`: irregular stress test for specific plastics;`}</li>
      <li parentName="ul"><strong parentName="li">{`Tensile test`}</strong>{`: tensile test for products with tensile demand;`}</li>
      <li parentName="ul"><strong parentName="li">{`Destructive testing`}</strong>{`: drop test, cross-cut test, adhesion test, etc;`}</li>
      <li parentName="ul"><strong parentName="li">{`Third-party testing`}</strong>{`: some inspections that cannot be executed internally will be completed with the help of third-party laboratories (such as SGS, CTI, and LCS).`}</li>
    </ul>
    <p>{`IQC incoming inspection is a kind of passive management. To improve the quality of incoming materials, we shall transform the passive inspection into active control, bring quality control forward, find the quality problems in the forefront, reduce the quality cost, achieve effective control, and assist suppliers to improve the quality control level internally.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      